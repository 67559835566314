<template>
  <div class="auth-form">
    <slot name="header" />
    <el-form
      ref="form"
      :model="form.data"
      :rules="form.rules"
      novalidate
      status-icon
      @submit.native.prevent="handleFormSubmit">
      <el-form-item
        :error="errors.email"
        :rules="form.rules.email"
        class="floating-label"
        prop="email">
        <el-input
          v-model="form.data.email"
          type="email"
          placeholder="Email/Username"
          class="form-item-input" />
        <div v-if="resetPasswordSent" class="el-form-item__error el-form-item__success">
          We have sent you an email containing a password reset link
        </div>
      </el-form-item>
      <el-button
        :loading="processing"
        type="primary"
        native-type="submit"
        class="next-button create-account-button">
        Email Me <i class="el-icon-arrow-right" />
      </el-button>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'ForgotPasswordForm',
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      }
    },
    processing: {
      type: Boolean,
      required: true
    },
    sent: {
      type: Boolean,
      required: true
    },
    savedEmail: {
      default: null
    }
  },
  data() {
    return {
      form: {
        data: {
          email: this.$store.getters.resetEmail
        },
        rules: {
          email: [{ required: true, type: 'string', min: 4, message: 'Email/Username is not valid', trigger: ['change', 'blur'] }]
        }
      },
      resetPasswordSent: false
    };
  },
  watch: {
    sent(newValue) {
      this.resetPasswordSent = newValue;
    },
    'form.data.email'() {
      this.resetPasswordSent = false;
    }
  },
  methods: {
    handleFormSubmit() {
      this.$refs.form.clearValidate();
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submit', Object.assign(this.form.data));
        }
      });
    }
  }
};
</script>
<style lang="scss">
.auth-form {
  margin: 0 auto;
  max-width: 460px;
  width: 100%;

  input[type='email']:disabled {
    color: $--color-primary;
  }

  .auth-header {
    margin-bottom: 0;
  }

  .auth-sub-header {
    font-size: $--jb-font-size-small;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  @include b(form) {
    @include b(form-item) {
      &.floating-label {
        margin-bottom: 32px;

        @include e(error) {
          left: 2px;
          text-align: left;
          top: 64px;
        }
      }
    }

    .el-button + .el-button {
      margin-left: 0;
    }
  }
}
</style>
