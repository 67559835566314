<template>
  <el-row justify="center" align="middle">
    <el-col :xs="{ span: 22, offset: 1 }" :sm="{span: 18, offset: 3}" :md="{span: 12, offset: 6}">
      <transition name="slide-fade" mode="out-in">
        <shop-organization-access
          v-if="orgId"
          :org-name="orgName"
          :org-id="orgId"
          :shop-domain="shopDomain" />
      </transition>
    </el-col>
  </el-row>
</template>

<script>
import ShopOrganizationAccess from '@/components/auth/ShopOrganizationAccess.vue';

export default {
  components: {
    shopOrganizationAccess: ShopOrganizationAccess
  },
  data() {
    return {
      currentView: 'shopOrganizationAccess'
    };
  },
  computed: {
    shopDomain() {
      return this.$store.getters.shopDetails.domain;
    },
    orgId() {
      return this.$store.getters.organization.id;
    },
    orgName() {
      return this.$store.getters.organization.name;
    }
  },
  methods: {
    toPageOne() {
      this.currentView = 'shopOrganizationAccess';
    }
  }
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.1s, transform 0.2s;
}

.slide-fade-enter,
.slide-fade-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.shop-int-conf-container {
  margin: 0 auto 143px;
  max-width: 496px;

  .shop-int-conf-header {
    margin-bottom: 0;
  }

  .shop-int-subheader {
    font-size: 14px;
    margin-bottom: 32px;
    margin-top: 8px;
  }
}
</style>
