<template>
  <div id="signin" class="container-fluid signin">
    <div class="main">
      <div class="signin-form">
        <div class="form-inner">
          <transition :name="headerTransition" mode="out-in">
            <div class="headers">
              <transition name="el-fade-in" mode="out-in">
                <h1 :key="mainHeaderText" class="main-header">
                  {{ mainHeaderText }}
                </h1>
              </transition>
            </div>
          </transition>
          <div class="form-holder clearfix">
            <transition name="el-fade-in" mode="out-in">
              <component
                :is="formComponent"
                :key="formComponent"
                :processing="form.processing"
                :errors="form.errors"
                :sent="form.sent"
                :password-reset-details="passwordResetDetails"
                :is-invitation="isInvitation"
                :one-password-input="onePasswordInput"
                @main-header-text="setMainHeaderText"
                @form-header-text="setFormHeaderText"
                @submit="handleFormSubmit">
                <h4 slot="header" class="auth-header tw-text-center" v-html="formHeaderText" />
                <el-button
                  v-if="formComponent === 'auth-form'"
                  slot="submit-button"
                  :loading="form.processing"
                  type="primary"
                  native-type="submit"
                  class="next-button">
                  Sign In <i class="el-icon-arrow-right" />
                </el-button>
              </component>
            </transition>
            <transition name="el-fade-in" mode="out-in">
              <div v-if="signinMode" key="get-started" class="get-started actions">
                Don't have an account? <router-link :to="{ name: 'signup' }" class="attention">
                  Get Started
                </router-link>
              </div>
              <div v-else-if="forgotPasswordMode" key="remember-password" class="remember-password actions">
                Recovered your password? <router-link :to="{ name: 'signin' }" class="attention">
                  Sign In
                </router-link>
              </div>
            </transition>
          </div>
          <app-animated-logo />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _get from 'lodash/get';
import AuthForm from '@/components/auth/AuthForm';
import ForgotPasswordForm from '@/components/auth/ForgotPasswordForm';
import ResetPasswordForm from '@/components/auth/ResetPasswordForm';
import OrgInviteForm from '@/components/auth/OrgInviteForm';
import ShopIntegrationConfirmation from '@/views/auth/ShopIntegrationConfirmation';
import AppAnimatedLogo from '@/components/AppAnimatedLogo';
import { handleAuthResponseError } from '@/helpers/ErrorHelper';

export default {
  name: 'SignInView',
  components: {
    AuthForm,
    ForgotPasswordForm,
    ResetPasswordForm,
    OrgInviteForm,
    ShopIntegrationConfirmation,
    AppAnimatedLogo
  },
  data() {
    return {
      mainHeaderText: this.$route.meta.mainHeader,
      formHeaderText: this.$route.meta.formHeader,
      form: {
        errors: {},
        processing: false,
        sent: false
      },
      headerTransition: ''
    };
  },
  computed: {
    formComponent() {
      return `${this.$route.meta.formComponent}-form`;
    },
    signinMode() {
      return this.$route.meta.formComponent === 'auth';
    },
    forgotPasswordMode() {
      return this.$route.meta.formComponent === 'forgot-password';
    },
    resetPasswordMode() {
      return this.$route.meta.formComponent === 'reset-password';
    },
    orgInviteMode() {
      return this.$route.meta.formComponent === 'org-invite';
    },
    onePasswordInput() {
      return this.$route.meta.onePasswordInput;
    },
    formAction() {
      if (this.forgotPasswordMode) {
        return 'sendResetPasswordToken';
      }
      if (this.resetPasswordMode) {
        return 'resetPassword';
      }
      if (this.orgInviteMode) {
        return 'acceptInvite';
      }
      return 'signin';
    },
    passwordResetDetails() {
      return this.$store.getters.passwordResetDetails;
    },
    isInvitation() {
      return this.passwordResetDetails !== null;
    }
  },
  watch: {
    $route() {
      this.form.errors = {};
    }
  },
  mounted() {
    this.headerTransition = 'el-fade-in';
  },
  methods: {
    setMainHeaderText(mainHeaderText) {
      this.mainHeaderText = mainHeaderText;
    },
    setFormHeaderText(formHeaderText) {
      this.formHeaderText = formHeaderText;
    },
    handleFormSubmit(formData) {
      this.form.errors = {};
      this.form.processing = true;
      this.form.sent = false;
      return this.$store
        .dispatch(this.formAction, formData)
        .then(() => {
          if (this.forgotPasswordMode) {
            this.form.processing = false;
          } else {
            const redirectTo = decodeURIComponent(this.$route.query.from || '/');
            this.$router.replace(redirectTo);
          }
          this.form.sent = true;
        })
        .catch(error => {
          error = _get(error, 'response.data._meta.errors', null);
          this.form.errors = handleAuthResponseError(Array.isArray(error) ? error : ['An unknown error occurred']);
          this.form.processing = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  margin: 0;
  padding: 0;
}

.signin-form {
  background: #fff;
  position: absolute;
  padding: $--clb-layout-4 $--clb-mobile-padding $--clb-layout-6 $--clb-mobile-padding;
  z-index: 10;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;

  .logo-link-portal {
    position: absolute;
    top: 0;
    left: 0;
    margin: $--clb-space-5 $--clb-space-5 0 $--clb-space-5;
  }

  .form-inner {
    width: 100%;
    max-height: 100%;
  }

  .main-header {
    color: $--clb-color__headings;
    font-size: 3rem;
    line-height: 3rem;
    font-weight: bold;
    margin: 0;
  }

  .auth-header {
    font-weight: 400;
    margin-bottom: 32px;
    margin-top: 8px;
  }

  .form-holder {
    position: relative;
  }

  .remember-password,
  .get-started,
  .actions {
    font-size: 14px;
    position: absolute;
    width: 100%;
    margin-bottom: 32px;

    .attention {
      letter-spacing: 0.5px;
    }
  }

  .pixels {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media (min-width: $--sm) {
  .signin-form {
    margin: 0 auto;
  }
}

@media (min-width: $--md) {
  .signin-form {
    padding-left: 40px;
    padding-right: 40px;
    width: 500px;

    .logo-link-portal {
      margin: 40px 40px 0 40px;
    }

    .headers,
    .form-holder {
      width: 100%;

      .main-header {
        margin: 0;
      }
    }

    .pixels {
      display: block;
      margin-left: 500px;
      width: calc(100% - 500px);
    }
  }
}

@media (min-width: $--lg) {
  .signin-form {
    width: 630px;

    .pixels {
      margin-left: 630px;
      width: calc(100% - 630px);
    }
  }
}
</style>
