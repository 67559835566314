<template>
  <div class="org-invite-form tw-text-left">
    <slot name="header" />
    <app-form
      ref="orgInviteForm"
      :model="form.data"
      :rules="form.rules"
      status-icon>
      <template v-if="!existingUser">
        <app-form-input
          v-model="form.data.first_name"
          name="first_name"
          size="large"
          placeholder="First Name"
          class="form-item-input"
          prop="first_name" />
        <app-form-input
          v-model="form.data.last_name"
          name="last_name"
          size="large"
          class="form-item-input"
          placeholder="Last Name"
          prop="last_name" />
      </template>

      <template v-else>
        <app-form-input
          v-model="form.data.email"
          name="email"
          size="large"
          placeholder="Email"
          class="form-item-input"
          prop="email" />
      </template>

      <app-form-input
        v-model="form.data.password"
        :error="errors.password"
        :readonly="readonly"
        name="org-invite-password"
        type="password"
        class="form-item-input"
        size="large"
        :placeholder="`Password ${!existingUser ? '(6+ Characters)' : ''}`"
        prop="password"
        @focus="readonly = false" />
    </app-form>
    <slot name="submit-button">
      <app-button
        :loading="processing"
        state="primary"
        class="tw-w-full tw-mb-space-4 tw-h-[60px]"
        @click="handleFormSubmit">
        {{ submitButtonText }} <i class="el-icon-arrow-right" />
      </app-button>
    </slot>
  </div>
</template>

<script>
import AppForm from '@/components/AppForm';
import AppFormInput from '@/components/AppFormInput';
import AppButton from '@/components/AppButton';

export default {
  name: 'OrgInviteForm',
  components: { AppForm, AppFormInput, AppButton },
  props: {
    errors: {
      type: Object,
      default: () => {
        return {};
      }
    },
    accountType: {
      type: String,
      default: ''
    },
    processing: {
      type: Boolean,
      required: true
    },
    passwordResetDetails: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      readonly: true,
      form: {
        data: {
          email: this.passwordResetDetails.user_email,
          first_name: '',
          last_name: '',
          password: ''
        },
        rules: {
          email: {
            required: true,
            message: 'Email is Required'
          },
          first_name: {
            required: true,
            message: 'First Name is Required'

          },
          last_name: {
            required: true,
            message: 'Last Name is Required'
          },
          password: [
            {
              required: true,
              min: 6,
              message: 'Password must contain at least 6 characters',
              trigger: ['change', 'blur']
            }
          ]
        }
      }
    };
  },
  computed: {
    existingUser() {
      return this.passwordResetDetails.user_status === 'approved' || false;
    },
    submitButtonText() {
      return this.existingUser ? 'Sign in' : `Create ${this.accountType} Account`;
    }
  },
  created() {
    if (this.existingUser) {
      this.$emit('main-header-text', 'Sign In');
      this.$emit('form-header-text', 'Sign In to your new Organization');
    }
  },
  methods: {
    handleFormSubmit() {
      this.$refs.orgInviteForm.$refs.form.clearValidate();
      this.$refs.orgInviteForm.$refs.form.validate(valid => {
        if (valid) {
          const response = {
            first_name: this.form.data.first_name,
            last_name: this.form.data.last_name,
            password: this.form.data.password,
            email: this.form.data.email,
            invite_token: this.passwordResetDetails.token
          };
          this.$emit('submit', response);
        }
      });
    }
  }
};
</script>

<style lang="scss">
.org-invite-form {
  max-width: 460px;
  margin: auto;
}
</style>
