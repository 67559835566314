<template>
  <div class="pixels">
    <div class="pixel">
      <svg id="platform" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -16 340 220">
        <g class="cls-1 main-box-shadow">
          <polygon points="336.6 118.5 168.9 216 0 118.5 167.8 21 336.6 118.5" />
        </g>
        <polygon class="cls-2 main-box" points="336.6 97.5 167.8 0 0 97.5 168.9 195 336.6 97.5" />
        <polygon class="cls-3 main-box" points="168.9 195 168.8 199 336.6 101.6 336.6 97.5 168.9 195" />
        <polygon class="cls-3 main-box" points="0 97.5 0 101.6 168.8 199 168.9 195 0 97.5" />
        <path
          class="cls-6 logo-shadow"
          d="M249.9,69.2a2.6,2.6,0,0,1,1.5,2.1l.3,38.1.1,7.3a16.78,16.78,0,0,1-.7,5.8,14.52,14.52,0,0,1-4,6.7,26.69,26.69,0,0,1-6.9,5.6c-7.5,4.4-17.6,6.4-27.5,6.1-3.7-.1-6.6,1.5-6.4,3.7a13.9,13.9,0,0,1-1.4,6.5,5,5,0,0,1-.8,1.4,23.29,23.29,0,0,1-8.7,8.3,44.06,44.06,0,0,1-8,3.5,48.81,48.81,0,0,1-10,2.4,66.43,66.43,0,0,1-19.4-.2,50,50,0,0,1-17.2-5.9L89,130.7c-3.1-1.8-4.8-4.1-4.8-6.6l-.3-45c-.1-2.5,1.7-4.9,4.7-6.7l21.9-12.7,55.4-32.2a12.92,12.92,0,0,1,11.5,0l42.9,24.8c3.2,1.8,3.2,4.8,0,6.6L184.6,79.7l-17.1,9.9-2.8,1.6-6.3,3.6c-5.3,3.1-13.9,3.1-19.2,0s-5-8.4.2-11.4c.1-.1.5-.3.8-.5l2.7-1.6c2.8-1.6,2.8-4.3,0-5.9l-8.7-5a11.44,11.44,0,0,0-10.2,0l-3,1.7-1.6.9L116,75c-.5.3-9.7,6.3-9.1,13.7.4,5.1,3.4,11.8,11,16.5l2.5,1.4a56.65,56.65,0,0,0,24.6,7.2,44,44,0,0,0,8.1.1,47.76,47.76,0,0,0,11.4-2c10.1-2.9,19.9-9,20.4-9.3l34.6-20.1,23-13.4A8.35,8.35,0,0,1,249.9,69.2Z"
          transform="translate(-1.7 -2)" />
        <path class="cls-4 logo-shadow" d="M83.8,76v0" transform="translate(-1.7 -2)" />
        <path
          class="cls-4 logo"
          d="M249.9,66.2a2.6,2.6,0,0,1,1.5,2.1l.3,38.1.1,7.3a16.78,16.78,0,0,1-.7,5.8,14.52,14.52,0,0,1-4,6.7,26.69,26.69,0,0,1-6.9,5.6c-7.5,4.4-17.6,6.4-27.5,6.1-3.7-.1-6.6,1.5-6.4,3.7a13.9,13.9,0,0,1-1.4,6.5,5,5,0,0,1-.8,1.4,23.29,23.29,0,0,1-8.7,8.3,44.06,44.06,0,0,1-8,3.5,48.81,48.81,0,0,1-10,2.4,66.43,66.43,0,0,1-19.4-.2,50,50,0,0,1-17.2-5.9L89,127.7c-3.1-1.8-4.8-4.1-4.8-6.6l-.3-45c-.1-2.5,1.7-4.9,4.7-6.7l21.9-12.7,55.4-32.2a12.92,12.92,0,0,1,11.5,0l42.9,24.8c3.2,1.8,3.2,4.8,0,6.6L184.6,76.7l-17.1,9.9-2.8,1.6-6.3,3.6c-5.3,3.1-13.9,3.1-19.2,0s-5-8.4.2-11.4c.1-.1.5-.3.8-.5l2.7-1.6c2.8-1.6,2.8-4.3,0-5.9l-8.7-5a11.44,11.44,0,0,0-10.2,0l-3,1.7-1.6.9L116,72c-.5.3-9.7,6.3-9.1,13.7.4,5.1,3.4,11.8,11,16.5l2.5,1.4a56.65,56.65,0,0,0,24.6,7.2,44,44,0,0,0,8.1.1,47.76,47.76,0,0,0,11.4-2c10.1-2.9,19.9-9,20.4-9.3l34.6-20.1,23-13.4A8.35,8.35,0,0,1,249.9,66.2Z"
          transform="translate(-1.7 -2)" />
        <path
          class="cls-5 logo"
          d="M116,76.1l3.4-2,1.6-1,3-1.7v-.05a11.48,11.48,0,0,1,10.1.05l8.8,5q2.1,1.35,2.1,3V75.3a3.87,3.87,0,0,0-2.1-3v.1l-8.8-5.1a11.44,11.44,0,0,0-10.2,0v.06L121,69l-1.6,1L116,72c-.5.3-9.1,5.9-9.1,13v4.1C106.9,82,115.5,76.4,116,76.1Z"
          transform="translate(-1.7 -2)" />
        <path
          class="cls-5 logo"
          d="M135.4,86.1v4.1c0,2,1.3,4,3.9,5.6,5.3,3.1,13.9,3.1,19.2,0l6.2-3.6,2.8-1.6,17-9.9,35.8-20.8v-.06a4,4,0,0,0,2.3-3.24V52.5c0,1.2-.7,2.4-2.4,3.3v.06L184.5,76.6l-17,9.9-2.8,1.6-6.2,3.6c-5.3,3.1-13.9,3.1-19.2,0C136.7,90.2,135.4,88.1,135.4,86.1Z"
          transform="translate(-1.7 -2)" />
        <path
          class="cls-5 logo"
          d="M251,119.4v.1a13.42,13.42,0,0,1-4,6.7v-.1a23.42,23.42,0,0,1-6.9,5.6c-7.6,4.4-17.6,6.4-27.5,6.1-3.6-.2-6.4,1.4-6.4,3.5v2.4a16.6,16.6,0,0,1-1.3,4.3,5,5,0,0,1-.8,1.4,21.87,21.87,0,0,1-8.7,8.3,38.7,38.7,0,0,1-8,3.5,50.45,50.45,0,0,1-10,2.4,60.14,60.14,0,0,1-19.4-.2,50.69,50.69,0,0,1-17.2-5.9v.1L89,127.7c-3-1.8-4.7-4.2-4.8-6.6v4.1c0,2.5,1.7,4.8,4.8,6.6v-.1l51.8,29.9a50,50,0,0,0,17.2,5.9,66.43,66.43,0,0,0,19.4.2,48.81,48.81,0,0,0,10-2.4,44.06,44.06,0,0,0,8-3.5,23.29,23.29,0,0,0,8.7-8.3,5,5,0,0,0,.8-1.4,14.45,14.45,0,0,0,1.4-5.6v-1.75c.51-1.75,3.09-2.94,6.3-2.85,9.9.3,20-1.7,27.5-6.1a26.69,26.69,0,0,0,6.9-5.6v.1a14.52,14.52,0,0,0,4-6.7v-.1a14,14,0,0,0,.8-4.9v-4.1A13.62,13.62,0,0,1,251,119.4Z"
          transform="translate(-1.7 -2)" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppAnimatedLogo'
};
</script>
<style lang="scss" scoped>
.pixels {
  background: $--color-primary;

  .pixel {
    padding-top: 120px;
    height: calc(90vh - 160px);
    height: calc(90 * var(--vh, 1vh) - 160px);
    min-height: 350px;
    align-items: center;
    animation-delay: 0.7s;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 15;
    backface-visibility: hidden;

    #platform {
      overflow: visible;
      margin-top: 60px;
      height: auto;
      width: 600px;
      padding: 40px;

      .cls-2 {
        fill: #f3f5fd;
      }

      .cls-3 {
        fill: #c9c9c9;
      }

      .cls-4 {
        fill: #5072ff;
      }

      .cls-5 {
        fill: #1e3bb0;
      }

      .cls-6 {
        fill: #bcbcbc;
      }

      .logo-shadow {
        animation: logoShadow 4s infinite ease-in-out;
        transform: translate(0, 0);
      }

      .logo {
        animation: logo 4s infinite ease-in-out;
        transform: translate(0, 0);
      }

      .main-box {
        animation: pixel 4s infinite ease-in-out;
        transform: translate(0, 0);
      }

      .main-box-shadow {
        animation: pixelShadow 4s infinite ease-in-out;
        transform: translate(0, -8px);
      }
    }
  }

  @keyframes logoShadow {
    0% {
      opacity: 1;
      transform: translate(0, 0);
    }

    50% {
      opacity: 0.6;
      transform: translate(0, -16px);
    }

    100% {
      opacity: 1;
      transform: translate(0, 0);
    }
  }

  @keyframes logo {
    0% {
      transform: translate(0, -2px);
    }

    50% {
      transform: translate(0, -32px);
    }

    100% {
      transform: translate(0, -2px);
    }
  }

  @keyframes pixel {
    0% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, -16px);
    }

    100% {
      transform: translate(0, 0);
    }
  }

  @keyframes pixelShadow {
    0% {
      opacity: 0.4;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0.4;
    }
  }
}
</style>
