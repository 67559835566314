<template>
  <div class="shop-organization-access-container">
    <el-row
      ref="shop-row-one"
      justify="center"
      align="middle"
      class="shop-conf-row-1">
      <el-col>
        <h1 class="shop-organization-access-header">
          Pair <i>{{ shopDomain }}</i> with <i>{{ orgName }}</i>?
        </h1>
        <div :class="slidingCheck ? 'sliding-animation' : ''" class="icon-cont">
          <span class="logos-span">
            <Shopify-logo class="shopify-logo" />
            <i class="el-icon-refresh refresh-icon" />
            <Logo class="logo" />
          </span>
          <i class="el-icon-circle-check" />
        </div>
        <p class="shop-int-subheader">
          In order to better customize your app, allow JB access so we can better help you. Members of <b>{{ orgName }}</b> will receive the ability to modify the tracking pixel configuration for <b>{{ shopDomain }}</b>
        </p>
        <el-button
          :loading="creatingShop || slidingCheck"
          type="primary"
          class="next-button"
          @click.prevent="handleClickConfirmButton">
          Yes
        </el-button>
        <el-button :disabled="creatingShop || singleOrganization" type="primary" class="next-button">
          Switch my Organization
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Logo from '@/assets/svg/logo.svg';
import ShopifyLogo from '@/assets/svg/brands/shopify_glyph.svg';

export default {
  components: {
    Logo,
    ShopifyLogo
  },
  props: {
    shopDomain: {
      type: String,
      required: true
    },
    orgId: {
      type: String,
      required: true
    },
    orgName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      slidingCheck: false
    };
  },
  computed: {
    creatingShop() {
      return this.$store.getters.creatingShop;
    },
    singleOrganization() {
      return this.$store.getters.user.orgs.length === 1;
    }
  },
  methods: {
    handleClickConfirmButton() {
      this.slidingCheck = true;
      this.$store
        .dispatch('createShop', {
          shopDomain: this.shopDomain,
          orgId: this.orgId
        })
        .then(() => {
          this.$router.push(decodeURIComponent(this.$route.query.from));
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.shop-int-subheader {
  line-height: 22px;
  margin-bottom: $--clb-layout-3 - 2;
  text-align: left;
}

.el-button + .el-button {
  margin-left: 0;
}

.icon-cont {
  overflow: hidden;
  padding: 24px 45px 0;

  .shopify-logo {
    height: 67px;
    width: 67px;
  }

  .refresh-icon {
    font-size: 46px;
  }

  .logo {
    color: $--jb-dark-primary-color;
    height: 58px;
    width: 58px;
  }

  .logos-span {
    align-items: center;
    display: flex;
    justify-content: space-around;
    transition: transform 0.5s cubic-bezier(0.72, -0.61, 0.25, 1.51);
  }

  .el-icon-circle-check {
    color: green;
    font-size: 40px;
    opacity: 0;
    transform: translate3d(-300px, -50px, 0);
  }
}
// On click of "Yes", this runs
.sliding-animation {
  .logos-span {
    opacity: 0;
    transform: translateY(200px) scale(0.3);
    transition: 500ms ease-in-out;
  }

  .el-icon-circle-check {
    opacity: 1;
    transform: translate3d(0, -55px, 0) scale(1.5);
    transition: 0.9s cubic-bezier(0.72, -0.61, 0.25, 1.51);
  }
}
</style>
